export const CustomChevronRightIcon = (): JSX.Element => {
	return (
		<svg width="4"
			height="7"
			viewBox="0 0 5 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.96084 4.00002L4.80956 1.15129C4.90187 1.05899 4.94909 0.956857 4.95122 0.84489C4.95337 0.732935 4.90615 0.628668 4.80956 0.53209C4.71298 0.435501 4.60978 0.387207 4.49996 0.387207C4.39014 0.387207 4.28505 0.437379 4.18471 0.537724L1.09421 3.62824C1.04292 3.68396 1.00446 3.74255 0.978824 3.80401C0.95318 3.86545 0.940358 3.9319 0.940358 4.00336C0.940358 4.07481 0.95318 4.14126 0.978824 4.20271C1.00446 4.26416 1.04292 4.32053 1.09421 4.37181L4.18471 7.46232C4.28077 7.55839 4.38201 7.60471 4.48842 7.60129C4.59482 7.59788 4.69631 7.54788 4.79289 7.45129C4.88948 7.35471 4.93777 7.25151 4.93777 7.14169C4.93777 7.03187 4.88948 6.92867 4.79289 6.83209L1.96084 4.00002Z"
				fill="black"
			/>
		</svg>
	);
};
