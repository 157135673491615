
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ROUTES: {[key: string]: string | any} = {
	BASENAME: '/',
	PAGES:{
		HOME:'/',
		GAME: '/game',
		CATEGORY: '/category',
		LOGIN: '/login',
		REGISTER: '/register'
	}
};
