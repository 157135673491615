import { GameCard, GameCardSlider, CategoryTopContainer } from 'casino/components/';
import { CategorySection, GameCardSection } from './CategoryContainer.style';
import { IGame } from 'casino/types';

interface ICategoryContainerProps {
	games: IGame[];
	sliderNo: number;
	title: string;
	viewAllLink: string;
	iconName: string;
	showGameInfoModal:(gameId: number, gameLink: string) => void;
	handleGameLoad: (gameLink: string, gameName: string, gameLaunchPoint: string, categoryName?: string) => void;
}

export const CategoryContainer = (
	{
		games,
		sliderNo,
		title,
		viewAllLink,
		iconName,
		showGameInfoModal,
		handleGameLoad
	}: ICategoryContainerProps): JSX.Element => {

	return (
		<CategorySection data-testid='category-section'>
			<CategoryTopContainer title={title}
				viewAllLink={viewAllLink}
				iconName={iconName} />

			<GameCardSection>
				<GameCardSlider sliderNo={sliderNo}>
					{
						games && games.length > 0 &&
						games.map(game => (
							<GameCard key={game.id}
								gameLaunchPoint='Lobby'
								showamount={false}
								gamename={game.name}
								gameimage={game.image_url}
								sash={game.sash}
								gameid={game.id}
								showgameinfomodal={showGameInfoModal}
								handleGameLoad={handleGameLoad}
							/>
						))
					}
				</GameCardSlider>
			</GameCardSection>
		</CategorySection>
	);
};

