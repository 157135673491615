export enum EventActions {
	deposit = 'DEPOSIT',
	withdraw = 'WITHDRAW',
	account = 'ACCOUNT',
	customerSupport = 'CUSTOMER_SUPPORT',
	safeGambling = 'SAFE_GAMBLING',
	register = 'REGISTER',
	login = 'LOGIN',
	gameLaunch = 'GAME_LAUNCH',
	sliderClick = 'SLIDER_CLICK',
	updateBalance = 'UPDATE_BALANCE',
	logout = 'LOGOUT'
}
