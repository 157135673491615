import { IButtonColorMap } from './Button.types';

export const ButtonsColorMap: IButtonColorMap = {
	Yellow: {
		border: '#F4BB40',
		background: '#F4BB40',
		text: '#010C2B',
		textWithoutIcon: '#F4BB40',
		textWithIcon: '#010C2B'
	},
	Green: {
		border: '#008751',
		background: '#008751',
		text: '#fff',
		textWithoutIcon: '#008751',
		textWithIcon: '#1E312E'
	},
	Gray: {
		border: '#F3F5F8',
		background: '#F3F5F8',
		text: '#1E312E',
		textWithoutIcon: '#F3F5F8',
		textWithIcon: '#F3F5F8'
	},
	Blue: {
		border: '#0D1335',
		background: '#0D1335',
		text: '#fff',
		textWithoutIcon: '#0D1335',
		textWithIcon: '#0D1335'
	},
	Dark: {
		border: '#4F5767',
		background: '#363C47',
		text: '#fff',
		textWithoutIcon: '#4F5767',
		textWithIcon: '#363C47'
	},
	White: {
		border: '#fff',
		background: '#fff',
		text: '#010C2B',
		textWithoutIcon: '#fff',
		textWithIcon: '#fff'
	},
};
