// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigationButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  border: 1px solid;
  background-color: #070C26;
  border-color: rgba(173, 185, 206, 0.3137254902);
  color: var(--primary-color);
  cursor: pointer;
}

.navigationButtonPrev {
  right: 34px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 0;
}

.navigationButtonNext {
  right: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.navigationButton.navigationButton-disabled {
  border-color: rgba(173, 185, 206, 0.2509803922);
  color: rgba(173, 185, 206, 0.2509803922);
  cursor: default;
}

.navigationButtonPrev.navigationButton-disabled {
  border-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/packages/components/GameCardSlider/GameCardSlider.css"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,iBAAA;EACA,yBAAA;EACA,+CAAA;EACA,2BAAA;EACA,eAAA;AACJ;;AAEA;EACI,WAAA;EACA,4BAAA;EACA,+BAAA;EACA,eAAA;AACJ;;AAEA;EACI,QAAA;EACA,6BAAA;EACA,gCAAA;AACJ;;AAEA;EACI,+CAAA;EACA,wCAAA;EACA,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".navigationButton {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 35px;\n    height: 30px;\n    overflow: hidden;\n    position: absolute;\n    top: -3px;\n    border: 1px solid;\n    background-color: #070C26;\n    border-color: #ADB9CE50;\n    color: var(--primary-color);\n    cursor: pointer;\n}\n\n.navigationButtonPrev {\n    right: 34px;\n    border-top-left-radius: 20px;\n    border-bottom-left-radius: 20px;\n    border-right: 0;\n}\n\n.navigationButtonNext {\n    right: 0;\n    border-top-right-radius: 20px;\n    border-bottom-right-radius: 20px;\n}\n\n.navigationButton.navigationButton-disabled {\n    border-color: #ADB9CE40;\n    color: #ADB9CE40;\n    cursor: default;\n}\n\n.navigationButtonPrev.navigationButton-disabled {\n    border-right: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
