import tw from 'tailwind-styled-components';

export const CategorySection = tw.div`
  flex
  flex-col
  justify-center
  bg-[#0D1335]
  mt-[28px]
  first-of-type:mt-[0]
  relative
`;

export const GameCardSection = tw.div`
  flex
  flex-row
  mt-[16px]
`;
