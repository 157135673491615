
import { StyledComponent } from 'casino/components/shared/StyledComponent';
import tw from 'tailwind-styled-components';

export const Container: StyledComponent = tw.div`
bg-[#0D1335]
bottom-[0px]
fixed
flex
w-full
height-[52px]
shadow-[0_2px_6px_0_#0D1335]
items-start
z-[1]
text-[11px]
text-[#687090]
max-w-screen-sm
self-center
`;
